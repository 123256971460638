@import '../../styles/breakpoints.scss';

.Root {
  font-size: var(--font-size-image-cred);
  color: var(--color-grey-100);
  position: absolute;
  right: 6px;
  bottom: 8px;
  transform: rotate(-180deg);
  writing-mode: vertical-lr;
  filter: flipV flipH;
  text-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25);
  opacity: 0.9;
  transform: translate3d(0, 0, 1);

  &.CardGlass {
    right: 0;
    @media (min-width: $breakpoint-m) {
      right: 6px;
    }
  }

  &.CardGlass.Reverse {
    right: unset;
    left: 0px;

    @media (min-width: $breakpoint-m) {
      left: 6px;
    }
  }
  &.Testimonial {
    right: unset;
    bottom: unset;
    top: 8px;
    left: 6px;
  }

  &.Stories {
    writing-mode: vertical-rl;
    padding-top: 2.4rem;
    @media (min-width: $breakpoint-ml) {
      padding-top: 0rem;
    }
  }

  &.VideoStory {
    writing-mode: vertical-rl;
    z-index: 1;
  }

  &.BottomMargin {
    bottom: 22px;
  }

  &.Dark {
    color: var(--color-grey-700);
  }
}
