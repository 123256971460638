@import '../../styles/breakpoints.scss';

.Root {
  margin-bottom: var(--module-bottom-margin);
  position: relative;

  &.White {
  }
}

.Wrap {
  position: relative;
}

.ImageContainer {
  margin-left: auto;
  position: relative;
  aspect-ratio: 3/2;
  width: calc(100% - 24px);
  grid-column-start: 2;
  grid-column-end: 6;

  .Reverse & {
    grid-column-start: 2;
    grid-column-end: 6;
    margin-left: 0;
  }
}

.CardContent {
  padding: 2.4rem 1.6rem;
  grid-column-start: 2;
  grid-column-end: 6;
  transform: translateY(-4rem);
  margin-right: 24px;

  .Reverse & {
    margin-left: 16px;
    margin-right: 0;
    grid-column-start: 2;
    grid-column-end: 6;
  }

  border-image-source: linear-gradient(
    113.59deg,
    rgba(255, 255, 255, 0.69) 7.33%,
    rgba(255, 243, 248, 0.5) 33.87%,
    rgba(255, 255, 255, 0) 50.55%,
    rgba(255, 255, 255, 0.15) 72.97%,
    rgba(255, 255, 255, 0.5) 99.02%
  );

  .NoImage & {
    transform: translateY(0);
    grid-column-end: 6;
  }
}
.Button {
  .NoImage & {
    margin-left: auto;
  }

  .Black {
    color: #fff;
  }
}

.Heading {
  font-size: var(--font-size-card-title);
  color: var(--color-black-800);
  text-transform: uppercase;
  margin-bottom: 1.2rem;
  letter-spacing: 1px;

  .Black & {
    color: #fff;
  }
}

.Text {
  font-size: var(--font-size-body-1);
  color: var(--color-black-750);
  line-height: 2.6rem;
  margin-bottom: 2.4rem;

  .Black & {
    color: var(--color-grey-10);
  }

  .NoImage & {
    max-width: 700px;
  }
}

@media (min-width: $breakpoint-m) {
  .Button {
    .NoImage & {
      padding-left: 48px;
    }
  }
  .Root {
  }
  .ImageContainer {
    grid-column-start: 5;
    grid-column-end: 13;
    grid-row: 1/2;
    align-self: center;
    transform: translateX(80px);
    transition: transform 1.2s ease;
    //filter: var(--drop-shadow-on-black);
    will-change: auto;
    .Reverse & {
      transform: translateX(-80px);
      grid-column-start: 3;
      grid-column-end: 11;
    }

    .Visible & {
      transform: translateX(0);
    }
  }
  .Text {
    .NoImage & {
      margin-bottom: 0;
    }
  }
  .CardContent {
    transform: unset;
    grid-column-start: 3;
    grid-column-end: 9;
    padding: 3.2rem 2.4rem;
    grid-row: 1/2;
    margin-right: 0;
    transform: translateX(-80px);
    align-self: center;
    transition: transform 1.2s ease;
    will-change: auto;
    .Reverse & {
      transform: translateX(80px);
      grid-column-start: 7;
      grid-column-end: 13;
    }

    .NoImage & {
      transform: translateY(80px) translateX(0);
      grid-column-end: 13;
      display: flex;
      align-items: center;
    }

    .Visible & {
      transform: translateX(0) translateY(0);
    }
  }
  .NoImage .CardContent {
    position: relative;
    top: unset;
  }
}

@media (min-width: $breakpoint-ml) {
  .Root {
  }
  .ImageContainer {
    grid-column-start: 5;
    grid-column-end: 13;
  }
  .Text {
    .NoImage & {
      margin-bottom: 0;
    }
  }
  .CardContent {
    grid-column-start: 3;
    grid-column-end: 8;
    padding: 3.8rem 4.8rem 4.2rem;
    height: fit-content;
    align-self: center;

    .NoImage & {
      grid-column-end: 13;
      display: flex;
      align-items: center;
    }
  }
  .NoImage .CardContent {
    position: relative;
    top: unset;
  }
}
