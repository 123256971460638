@import '../../styles/breakpoints.scss';

.Root {
  --color: var(--color-white);
  --size: var(--font-size-button-2);

  &.OnWhite {
    --color: var(--color-black-800);
  }
}
.Root.Small {
  --size: var(--font-size-button-2);
}

.Root.Stories {
  position: absolute;
  left: 16px;
  bottom: 16px;
  z-index: 2;
  gap: 8px;
  padding: 0;
  @media (min-width: $breakpoint-ml) {
    left: 24px;
    bottom: 24px;
    gap: 16px;
  }
}

.Root.Stories .Icon {
  width: 16.5px;
  height: 16.5px;

  @media (min-width: $breakpoint-ml) {
    width: 14.9px;
    height: 19.3px;
  }
}

.Root.StoriesLink {
  margin-top: auto;
  gap: 14px;

  @media (min-width: $breakpoint-ml) {
    margin-bottom: auto;
    align-self: flex-end;
    gap: 0;
  }
}

.Root {
  display: inline-flex;
  z-index: 0;
  gap: 16px;
  color: var(--color);
  cursor: pointer;
  align-items: center;
  font-size: var(--size);
  background: none;
  border: none;
}

.IconWrap {
  position: relative;
  // overflow: hidden;
  background-image: var(--gradient-pink-blue);
  border-radius: 100px;
  border: none;
  height: 4.8rem;
  width: 4.8rem;
  overflow: hidden;
  display: flex;
  justify-content: center;
  padding: 8px;
  align-items: center;
  --border: 1px;
  --radius: 100px;
  --border-background: linear-gradient(
    106.95deg,
    #ffffff 13.24%,
    rgba(255, 255, 255, 0) 29.86%,
    rgba(255, 255, 255, 0) 60.25%,
    #ffffff 86.44%
  );
  --border-background-hover: linear-gradient(
    293.99deg,
    #ffffff 10.27%,
    rgba(255, 255, 255, 0) 27.16%,
    rgba(255, 255, 255, 0) 58.06%,
    #ffffff 84.68%
  );
}

.NoPadding {
  padding: 0;
}

.NoPadding .IconWrap {
  padding: 0;
}

.Root.Accordion {
  gap: 0;
}

.Root.AccordionIcon .Icon {
  width: 2.4rem;
  height: 2.4rem;
}

.Root.Accordion .Icon {
  width: 2.8rem;
  height: 2.8rem;
}

.Root.Accordion .IconWrap {
  width: 3.6rem;
  height: 3.6rem;

  @media (min-width: $breakpoint-m) {
    height: 4rem;
    width: 4rem;
  }
}

.VideoPlayer .IconWrap {
  @media (min-width: $breakpoint-m) {
    height: 6.4rem;
    width: 6.4rem;
  }
}

.Icon {
  .Play & {
    margin-left: 5px;
  }
}
.Root:hover:before {
  opacity: 0;
}
.Root.Active:after,
.Root:hover:after {
  opacity: 1;
}

.IconWrap:before {
  content: '';
  display: block;
  background-image: var(--gradient-pink-blue);
  opacity: 1;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -2;
  transition: opacity 0.8s ease;
}

.IconWrap:after {
  content: '';
  display: block;
  background-image: var(--gradient-pink-blue-hover);
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -2;
  transition: opacity 0.8s ease;
}

.Root.IconHoverDown .Icon {
  transition: transform 0.2s ease;
}

.Root.IconHoverDown:hover .Icon {
  transform: translateX(5px);
}
.Text {
  color: var(--color);
}
@media (min-width: $breakpoint-m) {
  .Text {
    .ShowTextMobile & {
      border: 0 !important;
      clip: rect(1px, 1px, 1px, 1px) !important;
      -webkit-clip-path: inset(50%) !important;
      clip-path: inset(50%) !important;
      height: 1px !important;
      margin: -1px !important;
      overflow: hidden !important;
      padding: 0 !important;
      position: absolute !important;
      width: 1px !important;
      white-space: nowrap !important;
      text-transform: none;
    }
  }
}

@media (min-width: $breakpoint-m) {
  .IconRotate45 .Icon {
    rotate: 90deg;
  }
}

.PurpleBlue .IconWrap:before,
.PurpleBlue .IconWrap {
  background-image: var(--gradient-purple-blue);
}

.PinkBlue .IconWrap:before,
.PinkBlue .IconWrap {
  background-image: var(--gradient-pink-blue);
}

.PinkOrange .IconWrap:before,
.PinkOrange .IconWrap {
  background-image: var(--gradient-pink-orange);
}

.PinkPurple .IconWrap:before,
.PinkPurple .IconWrap {
  background-image: var(--gradient-pink-purple);
}

.BlueGreen .IconWrap:before,
.BlueGreen .IconWrap {
  background-image: var(--gradient-blue-green);
}

.IconWrap.Circle {
  padding: 1.5rem;
}

.InlineFlex {
  display: inline-flex;
  gap: 8px;
  align-items: center;
}

.Bg {
  content: '';
  position: absolute;
  z-index: 0;
  inset: 0;
  border-radius: var(--radius);
  padding: var(--border); /* the thickness of the border */
  /* the below will do the magic */
  -webkit-mask: linear-gradient(#fff 0 0) content-box,
    /* this will cover only the content area (no padding) */
      linear-gradient(#fff 0 0); /* this will cover all the area */
  -webkit-mask-composite: xor; /* needed for old browsers until the below is more supported */
  mask-composite: exclude; /* this will exclude the first layer from the second so only the padding area will be kept visible */
}

.Bg:before {
  content: '';
  position: absolute;
  left: 50%;
  top: 50%;
  width: 110%;
  height: 200%;
  background: var(--border-background);
  transform: translate(-46%, -50%) rotate(8deg);
  transition: transform 0.9s ease;
}

.Root:not(.Disabled):hover .Bg:before,
.Root:not(.Disabled).Active .Bg:before {
  transform: translate(-50%, -50%) rotate(187deg);
}

.Disabled.Root {
  --border-background: var(--color-grey-500);
  --border-background-hover: var(--color-grey-500);
  --color: var(--color-grey-500);
  cursor: default;
}
