.Root {
  pointer-events: none;
  --gradient: url('/img/gradient-purple-blue.webp');

  &.PinkOrange {
    --gradient: url('/img/gradients/pink-orange--800p.webp');
  }

  &.PinkBlue {
    --gradient: url('/img/gradients/pink-blue--800p.webp');
  }

  &.PinkPurple {
    --gradient: url('/img/gradients/pink-purple--800p.webp');
  }

  &.BlueGreen {
    --gradient: url('/img/gradients/green-blue--800p.webp');
  }
}

.Bg {
  &:after {
    content: '';
    background-color: transparent;
    filter: none;
    border-radius: 0;
    background-image: var(--gradient);
    background-repeat: no-repeat;
    position: absolute;
    top: 130%;
    left: 100%;
    width: 300%;
    height: 300%;
    background-size: contain;
    transform: translate3d(-50%, -50%, 0);

    .HomePage & {
      top: 60%;
      width: 220%;
      height: 220%;
      left: 70%;
    }

    .Reverse & {
      top: 30%;
      left: 0;
      transform: translate3d(-50%, -50%, 0) rotate(180deg);
    }
  }
}

.PurpleBlue.Reverse .Bg:after {
  background-color: transparent;
}
.PurpleBlue.Reverse .Bg:before {
  background-color: transparent;
}

.Reverse .Bg {
  &:after {
  }
  &:before {
  }
}
